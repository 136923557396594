import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  messageSource = new BehaviorSubject<string>("default message");
  currentMessage = this.messageSource.asObservable();
  dataSource = new BehaviorSubject<any>(null);
  currentData = this.dataSource.asObservable();

  messageNotifiContract = new BehaviorSubject<string>("default message");
  currentNotifiContract = this.messageNotifiContract.asObservable();
  // có thể subcribe theo dõi thay đổi value của biến này thay cho messageSource

  constructor() { }
 
  // method này để change source message 
  changeMessage(message: any) {
    this.messageSource.next(message);
  }

  // method này để change NotifiContract message
  changeNotifiContract(message: any) {
    this.messageNotifiContract.next(message);
  }

  changeHub(data: any) {
    this.dataSource.next(data);
  }
}